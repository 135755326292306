/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Slideshow, Image, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kódování"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center pb--60 pt--60" name={"uvod"} style={{"backgroundColor":"var(--color-custom-2)"}}>
          
          <ColumnWrap className="column__flex --center el--1 mt--16 flex--center" anim={null} style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"4"} animS={"3"} style={{"maxWidth":850}}>
              
              <Title className="title-box fs--72 w--900 title-box--invert" style={{"maxWidth":787}} content={"Co Vám nakódujeme?<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3 --anim-s4" anim={"3"} name={"prázdná"} animS={"4"} style={{"paddingTop":19,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box text-box--center fs--20" style={{"maxWidth":718}} content={"Máme bohaté zkušenosti s kódováním <span style=\"font-weight: bold;\">dodatečný úprav</span>, <span style=\"font-weight: bold;\">vlastních modulů</span> a <span style=\"font-weight: bold;\">skriptů</span> pro Váš e-shop na Shoptetu.<span style=\"font-weight: bold;\"><br></span><br>\"<span style=\"font-style: italic;\">Náš zákazník, Náš pán!</span>\", rádi plníme v rámci Shoptetu přání našich klientů a stavíme se novým výzvám.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Slideshow name={"u625zg581s9"}>
          
          <Column className="pb--60 pt--60" lightbox={false}>
            
            <ColumnWrap className="column__flex --center el--2" columns={"2"}>
              
              <ColumnWrapper className="--left" style={{"marginBottom":0}}>
                
                <Title className="title-box" content={"Výpočet dopravy dle API Toptrans"}>
                </Title>

                <Text className="text-box text-box--left" content={"V rámci kódování modulů máme hotové řešení pro získávání <span style=\"font-weight: bold;\">ceny dopravy z API Toptrans SK/CZ</span> na bázi <span style=\"font-weight: bold;\">váhy a rozměrů</span> produktů. Zákazníkovy se na bázi zadání <span style=\"font-weight: bold;\">Města a PSČ</span> zobrazí cena za dopravu a přidá do košíku. Může se poté <span style=\"font-weight: bold;\">zaplatit objednávku již s dopravou v rámci nákupního procesu.</span><br>"}>
                </Text>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image alt={""} src={"https://cdn.swbpg.com/t/36255/143db5380a864d0a94f270b69c4b7b9d_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":381}} srcSet={"https://cdn.swbpg.com/t/36255/143db5380a864d0a94f270b69c4b7b9d_s=350x_.png 350w, https://cdn.swbpg.com/t/36255/143db5380a864d0a94f270b69c4b7b9d_s=660x_.png 660w, https://cdn.swbpg.com/t/36255/143db5380a864d0a94f270b69c4b7b9d_s=860x_.png 860w, https://cdn.swbpg.com/t/36255/143db5380a864d0a94f270b69c4b7b9d_s=1400x_.png 1400w"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="pb--60 pt--60">
            
            <ColumnWrap className="column__flex --center el--2" columns={"2"}>
              
              <ColumnWrapper className="--left">
                
                <Title className="title-box" content={"Vyhledávače a filtrace produktů"}>
                </Title>

                <Text className="text-box text-box--left" content={"Další z řady našich často dodávaných modulů je <span style=\"font-weight: bold;\">filtrace</span> či <span style=\"font-weight: bold;\">vyhledávače&nbsp;</span>produktů, založený na <span style=\"font-weight: bold;\">kategoriích </span>nebo <span style=\"font-weight: bold;\">filtračních parametrech</span> u produktů. Je možné dodat <span style=\"font-weight: bold;\">vyhledávače / filtrace </span>- modelů aut, pneumatik, autobaterií, elektrospotřebiče - <span style=\"font-weight: bold;\">ale také pro všechny typy produktů</span>. <span style=\"font-weight: bold;\">Modul je možno různě modifikovat a rozšířená kritéria, parametry a kategorie</span>. Vše co pro Váš prodej potřebujete.&nbsp;"}>
                </Text>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image alt={""} src={"https://cdn.swbpg.com/t/36255/f451acaef45249ad9970f8c5894c3f2d_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":652}} srcSet={"https://cdn.swbpg.com/t/36255/f451acaef45249ad9970f8c5894c3f2d_s=350x_.png 350w, https://cdn.swbpg.com/t/36255/f451acaef45249ad9970f8c5894c3f2d_s=660x_.png 660w, https://cdn.swbpg.com/t/36255/f451acaef45249ad9970f8c5894c3f2d_s=860x_.png 860w, https://cdn.swbpg.com/t/36255/f451acaef45249ad9970f8c5894c3f2d_s=1400x_.png 1400w, https://cdn.swbpg.com/t/36255/f451acaef45249ad9970f8c5894c3f2d_s=2000x_.png 2000w"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="pb--60 pt--60">
            
            <ColumnWrap className="column__flex --center el--2" columns={"2"}>
              
              <ColumnWrapper className="--left">
                
                <Title className="title-box" content={"Hlavní nadpis #3"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Text className="text-box text-box--left" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, změnit velikost řádkování, zarovnat text na stranu a dokonce nastavit maximální šířku textu v pixlech. Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

        </Slideshow>


        <Column className="--center pb--02 pt--16" name={"ecgoxod7o4"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center fs--30" content={"<span style=\"font-weight: bold;\">#Konfigurátory produktů #Vlastní dopravy &nbsp;#Kalkulátory\n#Importy produktů do košíku\n#Vyhledávače\n#Překlady Šablon</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--30" name={"kontakt"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --shape5 --center el--1 pb--50 pt--50 flex--center" style={{"backgroundColor":"var(--white)"}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":595}}>
              
              <Title className="title-box fs--62" content={"Potřebuje něco? Ozvěte se nám."}>
              </Title>

              <Button className="btn-box btn-box--shape4 fs--22 mt--16" content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"Studio Digital &lt;br&gt; Adam Zgabaj"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}